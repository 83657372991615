$(document).ready(function() {
    // Custoe JS
    blockS_1();
    header_mappingID();
    // Header
    headerSCroll();
    search_Active();
    toggleMobileMenu();
    mappingMenu();
    mappinginfor();
    expandDescribe();

    // Body
    checkLayoutBanner();
    // Index Table
    showBackToTop();
    swiperInit();
    // Set Background
    setBackgroundElement();
    // AOS
    AOS.init({
        once: true,
    });
    // Brand
    scrollActive();
    stickyAnything();
    navBrand();
    // Product List
    filterCate();
    mapPoint();
    pagination();
});

function expandDescribe() {
    if ($(".product-describe .full-content > *").length == 0) {
        $(".product-describe .expand").addClass("hidden");
    }
    $(".product-describe .expand").on("click", function() {
        $(this)
            .siblings(".full-content")
            .toggleClass("active")
            .closest(".product-describe")
            .find(".expand")
            .toggleClass("active");
        if ($("body.en-us").length == 1) {
            if ($(this).hasClass("active")) {
                $(this).find("span").text("Collapse");
            } else {
                $(this).find("span").text("Show more");
            }
        } else {
            if ($(this).hasClass("active")) {
                $(this).find("span").text("Rút gọn");
            } else {
                $(this).find("span").text("Xem thêm");
            }
        }
    });
    if ($("body.en-us").length == 1) {
        $(".product-describe").find(".expand span").text("Show more");
    } else {
        $(".product-describe").find(".expand span").text("Xem thêm");
    }
}

function mapPoint() {
    // $(".image-map-wrapper img[usemap]").maphilight({
    //     fillColor: "3b5a56",
    //     fillOpacity: 0.7,
    //     stroke: true,
    //     strokeColor: "ffcb04",
    //     strokeCopacity: 1,
    //     strokeWidth: 2,
    //     fade: true,
    //     alwaysOn: false,
    //     neverOn: false,
    //     groupBy: false,
    //     wrapClass: true,
    //     shadow: false,
    //     shadowX: 0,
    //     shadowY: 0,
    //     shadowRadius: 6,
    //     shadowColor: "000000",
    //     shadowOpacity: 1,
    //     shadowPosition: "outside",
    //     shadowFrom: false,
    // });
    $(".image-map-wrapper img[usemap]").rwdImageMaps();
}

function headerSCroll() {
    // var lastScrollTop = 0;
    // $(window).scroll(function(event) {
    //     if (window.pageYOffset > 80) {
    //         var checkScroll = $(this).scrollTop();
    //         if (checkScroll > lastScrollTop) {
    //             $("header").addClass("header-scroll");
    //         } else {
    //             $("header").removeClass("header-scroll");
    //         }
    //         lastScrollTop = checkScroll;
    //     }
    // });
    window.addEventListener("scroll", function() {
        if (window.pageYOffset > 0 && $(window).width() >= 1200) {
            document.querySelector("header").classList.add("header-scroll");
        } else {
            document.querySelector("header").classList.remove("header-scroll");
        }
    });
}

function search_Active() {
    $("header .search-icon").on("click", function() {
        $(".search-overlay").addClass("active");
        $("body").addClass("disable");
    });
    $(document).on("click", function(event) {
        var $trigger = $("header");
        var $trigger_2 = $(".searchbox");
        if (
            $trigger !== event.target &&
            !$trigger.has(event.target).length &&
            $trigger_2 !== event.target &&
            !$trigger_2.has(event.target).length
        ) {
            $(".search-overlay").removeClass("active");
            $("body").removeClass("disable");
        }
    });
}

function checkLayoutBanner() {
    var heightHeader = $("header").outerHeight();
    $("main").css("padding-top", heightHeader);
}

function toggleMobileMenu() {
    var dropdown = $("header .dropdown-menu");
    $(".header_btn").on("click", function() {
        if (dropdown.hasClass("active")) {
            dropdown.removeClass("active");
            $("body").removeClass("disable");
            $("header .has-dropdown em").removeClass("active");
        } else {
            $(this).toggleClass("click");
            $(".menu_mobile").toggleClass("show");
            $(".overlay").toggleClass("show");
            $("header .has-dropdown em").removeClass("active");
        }
    });
    $(".overlay").on("click", function() {
        $(this).removeClass("click");
        $(".menu_mobile").removeClass("show");
        $(".overlay").removeClass("show");
        $("body").removeClass("disable");
        $("header .has-dropdown em").removeClass("active");
    });
}

function mappingMenu() {
    var solution = $("header #dd_solution");
    var product = $("header #dd_product");
    var support = $("header #dd_support");
    var project = $("header #dd_project");
    var solution_em = $("header #dd_solution em");
    var product_em = $("header #dd_product em");
    var support_em = $("header #dd_support em");
    var project_em = $("header #dd_project em");
    var solution_menu = $("header #solution_menu");
    var product_menu = $("header #product_menu");
    var support_menu = $("header #support_menu");
    var project_menu = $("header #project_menu");
    setTimeout(() => {
        $("header .menu").mapping({
            mobileWrapper: "header .mobile-wrapper",
            mobileMethod: "prependTo",
            desktopWrapper: "header .header_menu",
            desktopMethod: "prependTo",
            breakpoint: 1281,
        });
        $(".header_infor").css("display", "flex");
        solution_menu.mapping({
            mobileWrapper: "header .menu_mobile #dropdown",
            mobileMethod: "appendTo",
            desktopWrapper: "header .header_menu #dd_solution",
            desktopMethod: "appendTo",
            breakpoint: 1281,
        });
        product_menu.mapping({
            mobileWrapper: "header .menu_mobile #dropdown",
            mobileMethod: "appendTo",
            desktopWrapper: "header .header_menu #dd_product",
            desktopMethod: "appendTo",
            breakpoint: 1281,
        });
        support_menu.mapping({
            mobileWrapper: "header .menu_mobile #dropdown",
            mobileMethod: "appendTo",
            desktopWrapper: "header .header_menu #dd_support",
            desktopMethod: "appendTo",
            breakpoint: 1281,
        });
        project_menu.mapping({
            mobileWrapper: "header .menu_mobile #dropdown",
            mobileMethod: "appendTo",
            desktopWrapper: "header .header_menu #dd_project",
            desktopMethod: "appendTo",
            breakpoint: 1281,
        });
    }, 300);

    if ($(window).width() <= 1280) {
        solution_em.on("click", function() {
            solution_menu.addClass("active");
            $(this).toggleClass("active");
            $("body").addClass("disable");
        });
        product_em.on("click", function() {
            product_menu.addClass("active");
            $(this).toggleClass("active");
            $("body").addClass("disable");
        });
        support_em.on("click", function() {
            support_menu.addClass("active");
            $(this).toggleClass("active");
            $("body").addClass("disable");
        });
        project_em.on("click", function() {
            project_menu.addClass("active");
            $(this).toggleClass("active");
            $("body").addClass("disable");
        });
    }
}

function mappinginfor() {
    $("header .header_infor").mapping({
        mobileWrapper: "header .mobile-wrapper",
        mobileMethod: "appendTo",
        desktopWrapper: "header .header_main",
        desktopMethod: "prependTo",
        breakpoint: 1281,
    });
}

function showBackToTop() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 500) {
            $(".back-to-top").addClass("active");
        } else {
            $(".back-to-top").removeClass("active");
        }
    });

    $(".back-to-top").on("click", function(e) {
        e.preventDefault();
        $("html,body").animate({
            scrollTop: 0,
        });
    });
}

function swiperInit() {
    var main_banner = new Swiper("#main-banner .swiper-container", {
        spaceBetween: 30,
        centeredSlides: true,
        parallax: true,
        effect: "fade",
        autoplay: {
            delay: 4500,
        },
        speed: 300,
        observer: true,
        observeParents: true,
        pagination: {
            el: "#main-banner .swiper-pagination",
            dynamicBullets: true,
            clickable: true,
        },
    });
    // News Relate
    var news_relate = new Swiper(".news-relate .swiper-container", {
        // autoplay: {
        //     delay: 4500,
        // },
        speed: 300,
        observer: true,
        observeParents: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 15,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 15,
            },

            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1280: {
                spaceBetween: 30,
                slidesPerView: 4,
            },
        },
        navigation: {
            prevEl: ".news-relate .swiper-button-prev",
            nextEl: ".news-relate .swiper-button-next",
        },
    });
    // Product
    var product_slide = new Swiper(".product-slide .swiper-container", {
        // autoplay: {
        //     delay: 4500,
        // },
        speed: 300,
        observer: true,
        observeParents: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 15,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 15,
            },

            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1280: {
                spaceBetween: 30,
                slidesPerView: 4,
            },
        },
        navigation: {
            prevEl: ".product-slide .swiper-button-prev",
            nextEl: ".product-slide .swiper-button-next",
        },
    });
    // **** Project **** //
    var project_detail_thumbs = new Swiper(
        ".project-detail-thumbs .swiper-container", {
            freeMode: false,
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                },
            },
        }
    );
    var project_detail_top = new Swiper(
        ".project-detail-top .swiper-container ", {
            spaceBetween: 0,
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            thumbs: {
                swiper: project_detail_thumbs,
            },
            navigation: {
                prevEl: ".pages-project-detail-sec-1 .swiper-button-prev",
                nextEl: ".pages-project-detail-sec-1 .swiper-button-next",
            },
        }
    );
    var project_relate = new Swiper(
        ".project-detail-slide-2 .swiper-container", {
            // autoplay: {
            //     delay: 4500,
            // },
            speed: 300,
            observer: true,
            observeParents: true,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                },
                1280: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                },
            },
            navigation: {
                prevEl: ".project-detail-slide-2 .swiper-button-prev",
                nextEl: ".project-detail-slide-2 .swiper-button-next",
            },
        }
    );
    // **** Product **** //
    var product_detail_thumbs = new Swiper(
        ".pages-product-detial-1 .thumbs-slide .swiper-container", {
            freeMode: false,
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                },
            },
        }
    );
    var product_detail_top = new Swiper(
        ".pages-product-detial-1 .top-slide .swiper-container ", {
            spaceBetween: 0,
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            thumbs: {
                swiper: product_detail_thumbs,
            },
            navigation: {
                prevEl: ".pages-product-detial-1 .top-slide .swiper-button-prev",
                nextEl: ".pages-product-detial-1 .top-slide .swiper-button-next",
            },
        }
    );
}

function setBackgroundElement() {
    $("[setBackground]").each(function() {
        var background = $(this).attr("setBackground");
        var bgMobile = $(this).attr("setBackgroundMobile");
        var breakpoint = $(this).attr("breakpoint");
        if (typeof bgMobile != "undefined") {
            if ($(window).width() <= breakpoint) {
                $(this).css({
                    "background-size": "cover",
                    "background-position": "center center",
                    "background-image": "url(" + bgMobile + ")",
                });
            } else {
                $(this).css({
                    "background-size": "cover",
                    "background-position": "center center",
                    "background-image": "url(" + background + ")",
                });
            }
        } else {
            $(this).css({
                "background-size": "cover",
                "background-position": "center center",
                "background-image": "url(" + background + ")",
            });
        }
    });
    $("[setBackgroundRepeat]").each(function() {
        var background = $(this).attr("setBackgroundRepeat");
        $(this).css({
            "background-image": "url(" + background + ")",
            "background-repeat": "repeat",
        });
    });
}

function scrollActive() {
    var header = $("header").outerHeight();
    $(".nav-brand").activescroll({
        offset: header + 10,
    });
}

function stickyAnything() {
    $(".nav-brand").sticky({
        topSpacing: $("header").outerHeight(),
        zIndex: 10,
    });
}

function navBrand() {
    if ($(window).width > 1280) {
        $(window).on("scroll", function() {
            if ($(".sticky-wrapper").hasClass("is-sticky")) {
                $("header").addClass("disable_bsd");
            } else {
                $("header").removeClass("disable_bsd");
            }
        });
    }
}

function filterCate() {
    var item = $(".product-sidebar");
    var filter = $(".filter_tool");
    var main = $(".filter_tool .main");
    var close = $(".filter_tool .close-button");
    var body = $("body");
    main.on("click", function() {
        filter.addClass("active");
        item.addClass("active");
        body.addClass("disablE");
    });
    close.on("click", function() {
        filter.removeClass("active");
        item.removeClass("active");
        body.removeClass("disablE");
    });
}

function blockS_1() {
    $(".block_s-1 .row .col-lg-6 .row .col-sm-6 .content-system").removeClass(
        "big"
    );
}

function header_mappingID() {
    $("header .menu li.has-dropdown")
        .eq(0)
        .attr("id", "dd_solution")
        .find(".dropdown-menu")
        .attr("id", "solution_menu");
    $("header .menu li.has-dropdown")
        .eq(1)
        .attr("id", "dd_product")
        .find(".dropdown-menu")
        .attr("id", "product_menu");
    $("header .menu li.has-dropdown")
        .eq(2)
        .attr("id", "dd_project")
        .find(".dropdown-menu")
        .attr("id", "project_menu");
    $("header .menu li.has-dropdown")
        .eq(3)
        .attr("id", "dd_support")
        .find(".dropdown-menu")
        .attr("id", "support_menu");
}

function pagination() {
    $(".modulepager")
        .find(".pagination")
        .find("li>a.NextPage, li>a.LastPage, li>a.BackPage, li>a.FirstPage")
        .parent()
        .hide();
}
